// import Vue from 'vue';
// import Vuex from 'vuex';
// import modules from '@/components/';
//
// Vue.use(Vuex);
//
// export default new Vuex.Store({
//   modules,
// });
import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'

Vue.use(Vuex)

const mutations = {
  loader: () => {
    return true
  }
}

// Create a new store
const store = new Vuex.Store({
  mutations,
  modules
})

export default store
