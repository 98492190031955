const pages = [
  {
    path: 'dashboard',
    name: 'Dashboard',
    components: {
      default: () => import('@/pages/Dashboard/Dashboard.vue')
    }
  },
  {
    path: 'usuarios/:role',
    name: 'Usuários',
    components: {
      default: () => import('@/pages/Users/index.vue')
    }
  },
  {
    path: 'usuario',
    name: 'Novo Usuário',
    components: {
      default: () => import('@/pages/Users/form')
    }
  },
  {
    path: 'usuario/:id',
    name: 'Editar Usuário',
    components: {
      default: () => import('@/pages/Users/form/index.vue')
    }
  },
  {
    path: 'unidades-de-medida',
    name: 'Unidades de Medida',
    components: {
      default: () => import('@/pages/Unity/index.vue')
    }
  },
  {
    path: 'unidade-de-medida',
    name: 'Nova Unidade de Medida',
    components: {
      default: () => import('@/pages/Unity/form')
    }
  },
  {
    path: 'unidade-de-medida/:id',
    name: 'Editar Unidade de Medida',
    components: {
      default: () => import('@/pages/Unity/form')
    }
  },
  {
    path: 'material',
    name: 'Novo Material',
    components: {
      default: () => import('@/pages/Item/form')
    }
  },
  {
    path: 'material/:id',
    name: 'Editar Material',
    components: {
      default: () => import('@/pages/Item/form')
    }
  },
  {
    path: 'materiais',
    name: 'Materiais',
    components: {
      default: () => import('@/pages/Item/index.vue')
    }
  },
  {
    path: 'sugestao-materiais',
    name: 'Nova Sugestão',
    components: {
      default: () => import('@/pages/ItemSuggestion/form')
    }
  },
  {
    path: 'sugestoes',
    name: 'Sugestões',
    components: {
      default: () => import('@/pages/ItemSuggestion/index.vue')
    }
  },
  {
    path: 'sugestoes-materiais/:id',
    name: 'Editar Sugestão de Materiais',
    components: {
      default: () => import('@/pages/ItemSuggestion/form')
    }
  },
  {
    path: 'bancos',
    name: 'Bancos',
    components: {
      default: () => import('@/pages/Bank/index.vue')
    }
  },
  {
    path: 'banco',
    name: 'Novo Banco',
    components: {
      default: () => import('@/pages/Bank/form')
    }
  },
  {
    path: 'banco/:id',
    name: 'Editar Banco',
    components: {
      default: () => import('@/pages/Bank/form')
    }
  },
  {
    path: 'banners',
    name: 'Banners',
    components: {
      default: () => import('@/pages/Banner/list')
    }
  },
  {
    path: 'banner',
    name: 'Novo Banner',
    components: {
      default: () => import('@/pages/Banner/form')
    }
  },
  {
    path: 'banner/:id',
    name: 'Editar Banner',
    components: {
      default: () => import('@/pages/Banner/form')
    }
  },
  {
    path: 'categorias',
    name: 'Categorias',
    components: {
      default: () => import('@/pages/Category/index.vue')
    }
  },
  {
    path: 'categoria',
    name: 'Nova Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'categoria/:id',
    name: 'Editar Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'creditos-comprados',
    name: 'Creditos Comprados',
    components: {
      default: () => import('@/pages/Order/index.vue')
    }
  },
  {
    path: 'saques-entidade',
    name: 'Saques Entidade',
    components: {
      default: () => import('@/pages/Sake/index.vue')
    }
  },
  {
    path: 'saques-gerador',
    name: 'Saques Gerador',
    components: {
      default: () => import('@/pages/Sake/index.vue')
    }
  },
  {
    path: 'aprovar-transacoes',
    name: 'Transações para aprovação',
    components: {
      default: () => import('@/pages/Transactions/index.vue')
    }
  },
  {
    path: 'transaction-approve/:id',
    name: 'Aprovar transação',
    components: {
      default: () => import('@/pages/Transactions/form')
    }
  },
  {
    path: 'unidades-de-medida',
    name: 'Unidades de Medida',
    components: {
      default: () => import('@/pages/Unity/index.vue')
    }
  },
  {
    path: 'unidade-de-medida',
    name: 'Nova Unidade de Medida',
    components: {
      default: () => import('@/pages/Unity/form')
    }
  },
  {
    path: 'unidade-de-medida/:id',
    name: 'Editar Unidade de Medida',
    components: {
      default: () => import('@/pages/Unity/form')
    }
  },
  {
    path: 'bancos',
    name: 'Bancos',
    components: {
      default: () => import('@/pages/Bank/index.vue')
    }
  },
  {
    path: 'banco',
    name: 'Novo Banco',
    components: {
      default: () => import('@/pages/Bank/form')
    }
  },
  {
    path: 'banco/:id',
    name: 'Editar Banco',
    components: {
      default: () => import('@/pages/Bank/form')
    }
  },
  {
    path: 'categorias',
    name: 'Categorias',
    components: {
      default: () => import('@/pages/Category/index.vue')
    }
  },
  {
    path: 'categoria',
    name: 'Nova Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'categoria/:id',
    name: 'Editar Categoria',
    components: {
      default: () => import('@/pages/Category/form.vue')
    }
  },
  {
    path: 'produtos',
    name: 'Produtos',
    components: {
      default: () => import('@/pages/Product/index.vue')
    }
  },
  {
    path: 'produto',
    name: 'Novo Produto',
    components: {
      default: () => import('@/pages/Product/form.vue')
    }
  },
  {
    path: 'produto/:id',
    name: 'Editar Produto',
    components: {
      default: () => import('@/pages/Product/form.vue')
    }
  },
  {
    path: 'noticias',
    name: 'Notícias',
    components: {
      default: () => import('@/pages/News/index.vue')
    }
  },
  {
    path: 'noticia',
    name: 'Nova Notícia',
    components: {
      default: () => import('@/pages/News/form.vue')
    }
  },
  {
    path: 'noticia/:id',
    name: 'Editar Notícia',
    components: {
      default: () => import('@/pages/News/form.vue')
    }
  },
  {
    path: 'pedidos',
    name: 'Pedidos',
    components: {
      default: () => import('@/pages/Cart/index.vue')
    }
  },
  {
    path: 'pedido/:id',
    name: 'Ver Pedido',
    components: {
      default: () => import('@/pages/Cart/form.vue')
    }
  },
  {
    path: '/reset',
    name: 'Resetar Senha',
    component: () => import('@/pages/Pages/ResetPassword.vue')
  },
  {
    path: '/request',
    name: 'Requisitar Senha',
    component: () => import('@/pages/Pages/RequestPassword.vue')
  },
  {
    path: '/emails',
    name: 'E-mails',
    component: () => import('@/pages/Email/index')
  },
  {
    path: '/email/:id',
    name: 'Editar Template e-mail',
    components: {
      default: () => import('@/pages/Email/form')
    }
  },
  {
    path: '/cidade',
    name: 'Cidades Habilitadas',
    component: () => import('@/pages/Cities/index')
  },
  {
    path: '/reports',
    name: 'Relatórios',
    component: () => import('@/pages/Reports/index')
  },
  {
    path: '/reports/:id',
    name: 'Edição de relatórios',
    components: {
      default: () => import('@/pages/Reports/form')
    }
  }
]

export default pages
