/*

Esse arquivo só define a instãncia do VueRouter e algumas configurações
as "rotas" so definidas em routes.js

*/

import VueRouter from 'vue-router'
import routes from './routes'
import store from '@/store'
import Jwt from '@/util/jwt'
import cookies from 'vue-cookies'

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})

router.beforeEach(async (to, _from, next) => {
  store.dispatch('loader/setLoader', true)
  const auth = await Jwt.check()
  store.dispatch('loader/setLoader', false)
  document.getElementsByTagName('title')[0].innerText = `${to.name} | Lixtrip`

  if (auth === 200) {
    if (to.path === '/login') return next('/')
    if (!store.getters['auth/getRole'].includes('admin')) {
      cookies.remove('access_token')
      return next('/login')
    }
    return next()
  }

  if (auth !== 200) {
    if (to.path === '/login') return next()
    if (to.path === '/reset') return next()
    if (to.path === '/request') return next()
  }

  return next('/login')
})

export default router
