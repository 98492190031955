import Vue from 'vue'
import axios from './axios'
import Jwt from './jwt'
import Helpers from './helpers'

Vue.use(Jwt)
Vue.use(Helpers)

export default {
  axios
}
