import BaseInput from '@/components/Inputs/BaseInput.vue'
import BaseDropdown from '@/components/BaseDropdown.vue'
import Card from '@/components/Cards/Card.vue'
import BaseButton from '@/components/BaseButton.vue'
import BaseCheckbox from '@/components/Inputs/BaseCheckbox.vue'
import {
  Input,
  InputNumber,
  Tooltip,
  Popover,
  Select,
  Option,
  OptionGroup,
  Tag,
  Row,
  Col,
  Container,
  Table,
  TableColumn,
  Button,
  Radio,
  RadioGroup
} from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
  install (Vue) {
    Vue.component(BaseInput.name, BaseInput)
    Vue.component(BaseDropdown.name, BaseDropdown)
    Vue.component(Card.name, Card)
    Vue.component(BaseCheckbox.name, BaseCheckbox)
    Vue.component(BaseButton.name, BaseButton)
    Vue.use(Input)
    Vue.use(InputNumber)
    Vue.use(Tooltip)
    Vue.use(Popover)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(OptionGroup)
    Vue.use(Tag)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Container)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Button)
    Vue.use(Radio)
    Vue.use(RadioGroup)
  }
}

export default GlobalComponents
