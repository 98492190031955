import Vue from 'vue'

const helpers = {}

helpers.install = () => {
  const $helpers = {}

  $helpers.formatDate = (date, region) => {
    if (!date) return null
    const [year, month, day] = date.split('-')

    switch (region) {
      case 'br':
        return `${day}/${month}/${year}`
      default:
        return date
    }
  }

  $helpers.isNumber = function (e) {
    e = e || window.event
    var charCode = e.which ? e.which : e.keyCode
    return charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46
      ? e.preventDefault()
      : true
  }

  $helpers.moneymask = {
    decimal: '.',
    thousands: ',',
    prefix: 'R$ ',
    suffix: '',
    precision: 2,
    masked: false
  }

  Vue.prototype.$helpers = $helpers
}

export default helpers
